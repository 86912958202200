@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 !important;
  font-family: "Sora", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-size: 12pt !important;
  font-weight: 100 !important;
  line-height: 1.5rem !important; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h3 {
  font-size: 15pt;
  font-weight: 600;
  line-height: 3.25rem;
}

ol {
  list-style: decimal;
  margin-left: 1.5rem;
}

#root {
  height: 100%;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}