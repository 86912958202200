.adm-modal-body:not(.adm-modal-with-image) {
    padding-top: 37px;
}
.ant-btn-round.ant-btn-lg {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 58px !important;
    font-weight: 600;
}
.ant-modal-content {
    border-radius: 16px !important;
}